
.portal-loader {
    background: #fafafa;
    top: 0;
    width: 100%;
    min-height: 100vh;
    position: absolute;
}

.portal-loader-logo-wrapper {
    text-align: center;
    position: absolute;
    z-index: 102;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.pace-done .portal-loader-logo-wrapper {
    display: none;
}

.portal-loader-logo {
    width: 60px;
    height: 60px;
    animation: portal-logo 6s infinite;
    border-radius: 50%;
    -webkit-backface-visibility: hidden;
}

.portal-loader-logo:before {
    content: "";
    width: 39.6px;
    height: 39.6px;
    position: absolute;
    border-radius: 50%;
    top: 10.2px;
    left: 10.2px;
    z-index: 1;
}

.portal-loader-logo__circle {
    width: 48px;
    height: 48px;
    position: absolute;
    border-radius: 100%;
    background: linear-gradient(to left, var(--mdc-theme-secondary-light, red) 0%, rgba(255, 64, 129, 0) 90%);
    z-index: 2;
    -webkit-backface-visibility: hidden;
}

.portal-loader-logo__circle__inner {
    width: 12px;
    height: 12px;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background: #aaa;
}

.portal-loader-logo__circle__bottom {
    bottom: 0;
    left: 6px;
}

.portal-loader-logo__circle__top {
    left: 6px;
    background: linear-gradient(to right, var(--mdc-theme-secondary-light, red) 0%, rgba(255, 64, 129, 0) 90%);
}

.portal-loader-logo__circle__left {
    top: 6px;
    background: linear-gradient(to top, var(--mdc-theme-secondary-light, red) 0%, rgba(255, 64, 129, 0) 90%);
}

.portal-loader-logo__circle__right {
    top: 6px;
    right: 0;
    background: linear-gradient(to bottom, var(--mdc-theme-secondary-light, red) 0%, rgba(255, 64, 129, 0) 90%);
}

.portal-loader-logo-text {
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
    /* @alternate */
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

@keyframes portal-logo {
    0%, 100% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    to {transform: rotate(360deg);}
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner .8s linear infinite;
}
