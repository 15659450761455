@import '~@angular/material/theming';
@include mat-core();

$mat-santander-identity: (
    50: #fde6e7,
    100: #fecbca,
    200: #ffb2b3,
    300: #f79999,
    400: #f68080,
    500: #f36665,
    600: #f24c4c,
    700: #f13030,
    800: #ee1b14,
    900: #ec0000,
    A100: #f68080,
    A200: #f13030,
    A400: #ec0000,
    A700: #990000,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

$mat-gray-accent: (
    50: #DEEDF2,
    100: #DEEDF2,
    200: #DEEDF2,
    300: #DEEDF2,
    400: #DEEDF2,
    500: #76a2b3,
    600: #C3DEE7,
    700: #C3DEE7,
    800: #9BC3D3,
    900: #9BC3D3,
    A100: #DEEDF2,
    A200: #C3DEE7,
    A400: #C3DEE7,
    A700: #9BC3D3,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

$mat-santander-success: (
    50: #e1f0e4,
    100: #d0ead1,
    200: #c1e2c3,
    300: #b0ddb4,
    400: #a1d5a4,
    500: #92cf94,
    600: #82c987,
    700: #72c274,
    800: #63ba69,
    900: #63ba69,
    A100: #c1e2c3,
    A200: #92cf94,
    A400: #63ba69,
    A700: #10823d,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

$mat-santander-info: (
    50: #cff1f1,
    100: #a4e1e4,
    200: #8ddadc,
    300: #76d1d6,
    400: #5fcad0,
    500: #48c3cb,
    600: #49c2ca,
    700: #31bcc1,
    800: #1bb4bc,
    900: #1bb4bc,
    A100: #bbe8eb,
    A200: #76d1d6,
    A400: #1bb4bc,
    A700: #127f84,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

$mat-santander-warn: (
    50: #fff4d6,
    100: #feecab,
    200: #feecab,
    300: #ffe49c,
    400: #ffe184,
    500: #ffcc33, //#ffdb70,
    600: #fed65c,
    700: #fdd247,
    800: #ffcc36,
    900: #ffcc33,
    A100: #fef0c1,
    A200: #fed65c,
    A400: #ffcc33,
    A700: #9a701a,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

$mat-santander-danger: (
    50: #ecd7e0,
    100: #e3c3d2,
    200: #d8aec2,
    300: #cc9cb2,
    400: #c586a3,
    500: #b77597,
    600: #b05e84,
    700: #a24d77,
    800: #9e3766,
    900: #9e3667,
    A100: #e6c2d4,
    A200: #bc7295,
    A400: #9e3667,
    A700: #832645,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);


$santander-app-theme-light: mat-light-theme(
        mat-palette($mat-santander-identity),
        mat-palette($mat-gray-accent),
        mat-palette($mat-santander-danger));

@include angular-material-theme($santander-app-theme-light);

$tooltip-max-width: 500px !default;
$tooltip-padding-y: .45rem !default;
$tooltip-padding-x: .8rem !default;

$success: map-get($mat-santander-success, 900)!important;
$danger: map-get($mat-santander-danger, 900)!important;
$warning: map-get($mat-santander-warn, 900)!important;
$warning-dark: map-get($mat-santander-warn, A700);
$info: map-get($mat-santander-info, 900)!important;

$gray-light: #DEEDF2;
$gray-medium: #9BC3D3;
$gray-dark: #76a2b3;
$gray: #C3DEE7;

$graphite: #6F7779;
$graphite-dark: #5a5a5a;
$graphite-light: #e1e1e1;


    /* TOASTR BUGFIX */
#toast-container > div {
    opacity: 1;
}
.toast {
    border: initial !important;
    backdrop-filter: blur(0) !important;
}
.toast-success {
    background-color: $success;
}
.toast-error {
    background-color: $danger;
}
.toast-info {
    background-color: $info;
}
.toast-warning {
    background-color: $warning;
}
