@import "variables";

.portal-widget__heading {
    text-transform: uppercase;
    padding-left: 8px;
    border-left-width: 2px;
    border-left-style: solid;
    position: relative;
    border-color: $graphite-light;

    @media (max-width: 500px) {
        flex-flow: column;

        span {
            margin-top: 10px;
            float: left;
        }
    }
}

.page-content:hover .portal-widget__heading:after {
    height: 100%;
}

.portal-widget__heading:after {
    content: "";
    width: 2px;
    height: 0%;
    position: absolute;
    bottom: 0;
    left: -2px;
    transition: height .5s;
    background-color: $gray-dark;
}

@keyframes bounce {
    0%, 20% {
        transform: translateY(0px);
    }
    10% {
        transform: translateY(-15px);
        opacity: .5;
    }
}
