/**
 * Google MDC
 */
@import url("https://fonts.googleapis.com/css?family=Ubuntu+Condensed|Ubuntu:300,400,500,700");
/**
 * Font Awesome
 */
@import "~@fortawesome/fontawesome-free/css/all.min.css";
/**
 * Toastr
 */
@import "~ngx-toastr/toastr.css";
/**
 * Modules
 */
@import "modules/variables";
@import "modules/font";
@import "modules/cursor";
@import "modules/table";
@import "modules/widget";
@import "modules/error";
@import "modules/color";
@import "modules/loader";
@import "~handsontable/dist/handsontable.full.css";
@import "modules/tree";
@import "node_modules/angular-archwizard/archwizard";

.d-grid {
    display: grid !important;
}

label {
    margin-bottom: 0 !important;
}

.ml-6 {
    margin-left: 1.5rem !important;
}

.htCore td.customClass {
    color: #f8f8ff;
    background: #1e90ff;
}

/**
 * Main
 */
.page-content {
    min-height: calc(100% - 30px);
    padding: 1.5rem;
    position: relative;
}

.form-control:focus {
    color: #495057;
    background-color: initial;
    border: none;
    box-shadow: none;
}

// Styles.scss

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px; // size of vertical scrollbar
    height: 8px; // size of horizontal scrollbar
}

//colors and appearence
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.2);
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -webkit-font-kerning: auto;
    font-kerning: auto;
    overflow: visible;
}

a {
    text-decoration: none;
    color: $gray-dark;

    &:hover {
        text-decoration: underline;
    }
}

h1 {
    line-height: 32px;
    font: 400 24px/32px Barlow, sans-serif;
    margin: 0 0 16px;
}

.buttons-fixed-insertions {
    position: fixed;
    top: 88px;
    z-index: 2;
    left: auto;
    right: 36px;
}
