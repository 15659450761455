@import '~angular-tree-component/dist/angular-tree-component.css';

// tree-root changes
.toggle-children {
    background: none !important;
}
.toggle-children::before{
    content: "\f07b";
    font-family: 'Font Awesome 5 Free';
    color: #f10007;
}
.toggle-children-wrapper-expanded .toggle-children{
    transform: none;
    -webkit-transform:none;
}
.toggle-children-wrapper-expanded .toggle-children::before{
    content: "\f07c";
    font-family: 'Font Awesome 5 Free';
    color: #f10007;
}
