@import "variables";

.table-flat {
    width: 100%;
    border-collapse: collapse;

    &:focus {
        outline: none;
    }

    & tbody {

        & tr {
            line-height: 2;

            & td {
                min-width: 250px;
                border-bottom: 1px solid $gray;
                padding: 0 1rem;
            }

            &:hover {
                background-color: $gray-light;
            }
        }
    }
}

.table-flat-horizontal {
    width: 100%;
    border-collapse: collapse;

    &:focus {
        outline: none;
    }

    & thead {
        vertical-align: inherit;
        border-radius: 5px;

        th{
            background-color: $gray;
            line-height: 3;
            text-align: center;

            &:first-child {
                border-top-left-radius: 5px;
            }

            &:last-child {
                border-top-right-radius: 5px;
            }
        }

    }

    & tbody {

        & tr {
            line-height: 2;

            & td {
                border-top: 1px solid $gray;
                text-align: center;
            }

            &:hover {
                background-color: $gray-light;
            }
        }
    }
}

.table-flat-horizontal-min {
    width: 100%;
    border-collapse: collapse;

    &:focus {
        outline: none;
    }

    & thead {
        vertical-align: inherit;

        th{
            line-height: 2;
            text-align: center;
        }

    }

    & tbody {

        & tr {
            line-height: 2;

            & td {
                border-top: 1px solid $gray;
                text-align: center;
            }

            &:hover {
                background-color: $gray-light;
            }
        }
    }
}

.table-responsive {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    overflow: visible;
    display: table;

    & th {
        position: sticky;
        top: 0;
        z-index: 200;
        background-color: #fafafa;

        & .sort {
            z-index: 300;
            margin-right: 15px;
            margin-left: -15px;
        }

        & td {
            padding-left: 5px;
        }
    }

    & tr {
        border-bottom: 1px solid $gray;

        &:hover {
            background-color: $gray-light;
        }

        & td {
            padding-left: 5px;
        }
    }
}

.confirmation:hover {
    background-color: $graphite-light;
}

.portal-table-tab-panel {
    display: none;

    &.portal-table-tab-panel--active {
        display: table;
    }
}

.portal-access-level-table {
    .portal-access-level-title {
        padding-top: 10px;
        padding-bottom: 10px;
        background: #eee;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.handsontable .handsontable-color__render {
    background-color: #d9edf7 !important;
    //color: rgb(228, 246, 254) !important;
}

.handsontable .handsontable-color__render-cm {
    background-color: #f7e5d9 !important;
}

.handsontable .handsontable-color__invalid {
    background-color: map-get($mat-santander-danger, 200) !important;
}

.handsontable .handsontable-color__invalid-uniq {
    background-color: #ff4c42 !important;
}

.handsontable .handsontable-color__atention {
    background-color: map-get($mat-santander-warn, 200) !important;
}

.handsontable .handsontable-color__atention-uniq {
    background-color: map-get($mat-santander-warn, 300) !important;
}

.handsontable .handsontable-uncolor__render {
    background-color: rgb(255, 255, 255)!important;
    color: rgb(86, 86, 86) !important;
}


.handsontable .handsontable-disabled {
    background-color: #cccccc !important;
    color: #a49898 !important;
}


.handsontable .handsontable-semi-disabled {
    background-color: #eee !important;
    color: #999 !important;
}
