@import "variables";

.color-santander {
    color: map-get($mat-santander-identity, 900)!important;
}
.bg-color-santander {
    background-color: map-get($mat-santander-identity, 900)!important;
    color: #fff!important;
}
.color-santander-dark {
    color: map-get($mat-santander-identity, A700)!important;
}
.bg-color-santander-dark {
    background-color: map-get($mat-santander-identity, A700)!important;
    color: #fff!important;
}
.color-danger {
    color: map-get($mat-santander-danger, 900)!important;
}
.bg-color-danger {
    background-color: map-get($mat-santander-danger, 900)!important;
    color: #fff!important;
}
.color-warning {
    color: map-get($mat-santander-warn, 900)!important;
}
.bg-color-warning {
    background-color: map-get($mat-santander-warn, 900)!important;
    color: #fff!important;
}
.color-success {
    color: map-get($mat-santander-success, 900)!important;
}
.bg-color-success {
    background-color: map-get($mat-santander-success, 900)!important;
    color: #fff!important;
}
.color-info {
    color: map-get($mat-santander-info, 900)!important;
}
.bg-color-info {
    background-color: map-get($mat-santander-info, 900)!important;
    color: #fff!important;
}
.color-gray {
    color: #6F7779;
}
.bg-color-gray {
    background-color: #6F7779;
}
.color-lgray {
    color: map-get($mat-grey, 300);
}
.bg-color-lgray {
    background-color: map-get($mat-grey, 300)!important;
}
.color-white {
    color: #fff!important;
}
.bg-color-white {
    background-color: #fff;
}

.bg-color-blue-lgray {
    background-color: $gray-light;
}
