@import './variables';

.portal-card {

    &-content {
        flex: 1 1 100%;
        position: relative;
    }

    &-error {
        transform: translateY(20px);
        opacity: 0;
        animation: portal-card-error 1s forwards 1;
        animation-delay: 0.6s;
        z-index: 1;

        &-header {
            font-size: 5rem;
        }

        &-content {
            padding: 48px;
            max-width: 400px;
            min-width: 300px;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            background-color: white;
            position: relative;
            border-radius: 8px;
        }
    }
}

@keyframes portal-card-error {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
