@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Ubuntu:wght@400;500&display=swap');

h1, h2, h2, h3, h4 {
    font-family: 'Ubuntu', sans-serif!important;
}

div, input, label, tr, td, div, li, ol, span, a, button, p, small, select, strong {
    font-family: Arial, Helvetica, sans-serif;
}

/*.mdc-text-field__input, .mdc-floating-label, .mdc-select__native-control, .mdc-button, .mdc-menu, .mdc-list-item, .condensed-text, .mdc-label {
    font-family: Arial, Helvetica, sans-serif;
}*/
