// Cursor

.cursor {
    &--pointer {
        cursor: pointer;
        pointer-events: auto !important;
    }

    &--not-allowed {
        cursor: not-allowed;
    }

    &--help {
        cursor: help;
    }

    &--default {
        cursor: default;
    }
}
